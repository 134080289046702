import React, { useEffect } from "react";
import Routes from "../routes";
import Navigation from "../components/navigation";
import Footer from "../components/footer";
import ScrollToTop from "../components/scrolltop";
import Head from "./Head";
import FooterApp from "../components/footer-board";
import TawkTo from "tawkto-react";
import WhatsApp from "../components/whatsapp-chat";

const App = () => {
  useEffect(() => {
    var tawk = new TawkTo("6241a8ef0bfe3f4a87700b67", "1fv87nqqn");

    tawk.onStatusChange(status => {
      // console.log(status)
    });
  }, []);
  return (
    <div className="app">
      <Head />
      <Navigation />
      <ScrollToTop />
      <main className="main">
        <Routes />
      </main>
      <WhatsApp />
      <Footer />
      <FooterApp />
    </div>
  );
};

export default App;
